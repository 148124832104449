//Controla el clic enla notificación puswh
// function setPushNotificationClick() {

    // Lógica de tu código JS aquí
    self.addEventListener('notificationclick', function(event) {
        const notificationData = event.notification.data;
    
        let urlToOpen = '/'; // Valor por defecto si no hay URL en la notificación
        if (notificationData && notificationData.url) {
          alert(notificationData.url);
          urlToOpen = notificationData.url; // URL recibida desde la carga útil del backend
        }
    
        event.notification.close(); // Cierra la notificación después del clic
    
        event.waitUntil(
          clients.matchAll({ type: 'window', includeUncontrolled: true }).then(function(clientList) {
            for (let i = 0; i < clientList.length; i++) {
              let client = clientList[i];
              if (client.url.includes(urlToOpen) && 'focus' in client) {
                return client.focus();
              }
            }
            if (clients.openWindow) {
              return clients.openWindow(urlToOpen); // Abre la URL si la aplicación no está abierta
            }
          })
        );
      });
  
// }
